import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, minLength, maxLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'deliveryRegions.create',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        country_id: null,
        status: false,
        translations: [],
      },
      currentTranslations: 0,
      validationErrors: {},
    }
  },
  components: {},
  validations: {
    payload: {
      country_id: {
        required
      },
      translations: {
        $each: {
          title: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(20)
          }
        }
      }
    }
  },
  created() {
    if (this.countries.length === 0) {
      this.fetchCountries().then(()=>{
        this._applyData();
      })
    } else {
      this._applyData();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      isDeliveryRegionCreating: 'deliveryRegions/isDeliveryRegionCreating',
      countries: 'countries/countries',
      isCountriesLoading: 'countries/isCountriesLoading',
    }),
    ...mapGetters([
      'languages',
    ]),
    countryErrors() {
      let error = [];
      if (!this.$v.payload.country_id.$dirty) {
        return error;
      }
      if (!this.$v.payload.country_id.required) {
        error.push(this.$t('validationRequired'));
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      createDeliveryRegions: 'deliveryRegions/DELIVERY_REGIONS_REQUEST_CREATE',
      fetchCountries: 'countries/COUNTRIES_REQUEST_LIST_GET',
    }),
    _applyData() {
      this.payload.translations = this.languages.map(langItem => {
        return {
          locale: langItem.translationKey,
          title: '',
        }
      });
    },
    translationsTitleErrors(index) {
      let error = [];
      if (!this.$v.payload.translations.$each[index].title.$dirty) {
        return error;
      }
      if (!this.$v.payload.translations.$each[index].title.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.translations.$each[index].title.minLength) {
        error.push(this.$t('validationMin',{count: 1}));
      }
      if (!this.$v.payload.translations.$each[index].title.maxLength) {
        error.push(this.$t('validationMax',{count: 20}));
      }
      return error;
    },
    submit() {
      if (!this.$v.$invalid) {

        let payload = {
          status: this.payload.status,
          country_id:  this.payload.country_id
        }
        this.payload.translations.forEach(item => {
          payload[item.locale] = {
            title: item.title,
          }
        });

        this.createDeliveryRegions(payload).then(() => {
          this.$toasted.success(this.$t('dataUpdated'));
          this.$router.push({name: 'deliveryRegions.list'}).catch(()=> {console.log()})
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    }
  }
}
